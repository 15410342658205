import React from "react"

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="80"
    height="80"
    viewBox="0 0 156 156"
  >
    <defs>
      <path
        id="a"
        d="M57.648 35.273h18.818v73.636c-.034 6.75-1.466 12.554-4.296 17.412-2.83 4.858-6.775 8.59-11.838 11.199-5.062 2.608-10.951 3.912-17.667 3.912-6.137 0-11.65-1.1-16.543-3.298-4.892-2.2-8.77-5.464-11.633-9.793-2.864-4.33-4.296-9.716-4.296-16.16h18.87c.034 2.83.656 5.268 1.866 7.313 1.21 2.046 2.89 3.614 5.037 4.705 2.148 1.09 4.62 1.636 7.415 1.636 3.034 0 5.608-.639 7.721-1.917 2.114-1.279 3.725-3.18 4.833-5.702 1.108-2.523 1.679-5.625 1.713-9.307V35.273zm.4 104.727V35.273h40.09c7.569 0 13.867 1.184 18.896 3.554 5.028 2.369 8.795 5.6 11.3 9.69 2.506 4.091 3.76 8.727 3.76 13.91 0 4.26-.819 7.9-2.455 10.917-1.637 3.017-3.835 5.463-6.597 7.338-2.761 1.875-5.846 3.221-9.255 4.04v1.022c3.715.205 7.286 1.338 10.713 3.4 3.426 2.063 6.23 4.97 8.412 8.72 2.181 3.75 3.272 8.284 3.272 13.602 0 5.42-1.312 10.287-3.937 14.6-2.625 4.312-6.58 7.712-11.864 10.201C115.1 138.756 108.451 140 100.44 140H58.048zm18.971-15.852h20.404c6.886 0 11.855-1.321 14.906-3.963 3.051-2.642 4.577-6.043 4.577-10.202 0-3.102-.767-5.906-2.301-8.412-1.534-2.506-3.716-4.483-6.546-5.932-2.83-1.449-6.204-2.173-10.125-2.173H77.02v30.682zm0-44.335h18.768c3.272 0 6.221-.606 8.846-1.816s4.696-2.932 6.213-5.165c1.517-2.233 2.276-4.883 2.276-7.951 0-4.057-1.424-7.398-4.27-10.023-2.847-2.625-7.065-3.938-12.656-3.938H77.019v28.892z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(0 -8.5)">
        <circle cx="45" cy="53.5" r="45" fill="#14A99D"></circle>
        <circle
          fill="#0B7BD2"
          style={{ mixBlendMode: "multiply" }}
          cx="45"
          cy="119.5"
          r="45"
        ></circle>
        <circle
          fill="#C421DC"
          style={{ mixBlendMode: "multiply" }}
          cx="111"
          cy="119.5"
          r="45"
        ></circle>
        <circle
          fill="#ff7c00"
          style={{ mixBlendMode: "multiply" }}
          cx="111"
          cy="54.5"
          r="45"
        ></circle>
        <g fill="#FFF" fillRule="nonzero">
          <use xlinkHref="#a"></use>
          <use style={{ mixBlendMode: "multiply" }} xlinkHref="#a"></use>
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
